import { SET_PRODUCTS, SET_VIEW, UPDATE_PRODUCT } from './constants';

const initialState = {
   curView: '',
   selectedProduct: null,
   products: [],
};

const reviewsReducer = (state = initialState, action) => {
   let index = null;
   let clone = null;
   const getIndex = product => {
      return state.products.findIndex(product => product.itemcode === action.product.itemcode);
   };

   switch (action.type) {
      case SET_PRODUCTS():
         return {
            ...state,
            products: action.products,
         };
      case SET_VIEW():
         return {
            ...state,
            curView: action.view,
         };
      case UPDATE_PRODUCT():
         index = getIndex(action.product);
         clone = Array.convert(state.products);
         clone[index] = action.product;

         return {
            ...state,
            products: clone,
         };
      default:
         return state;
   }
};

export default reviewsReducer;
