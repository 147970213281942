import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import reviewsReducer from 'Guide/ProductReviews/reducer';
import {
   TEAM_MEMBERS_STATE_ROOT,
   teamMembersReducer,
} from 'Guide/Courses/store/team-members.state';
import {
   INDIVIDUAL_DETAILS_STATE_ROOT,
   individualDetailsReducer,
} from 'Guide/Courses/store/individual-details.state';
import { COMPLETIONS_STATE_ROOT, completionsReducer } from 'Guide/Courses/store/completions.state';
import headerReducer from 'Shared/Header/reducer';
import popupReducer from 'Shared/PopupModal/reducer';
import bannerReducer from 'Shared/Announcements/reducer';
import { modalIsOpenSlice } from 'Shared/Header/shared-state';

/* Define the Reducers that will always be present in the application.
 * We need at least one reducer initially, so 'global' will be an empty reducer
 * that we can potentially use in the future if need be.
 */
const staticReducers = {
   reviewsState: reviewsReducer,
   [TEAM_MEMBERS_STATE_ROOT]: teamMembersReducer,
   [INDIVIDUAL_DETAILS_STATE_ROOT]: individualDetailsReducer,
   [COMPLETIONS_STATE_ROOT]: completionsReducer,
   headerState: headerReducer,
   popupState: popupReducer,
   bannerState: bannerReducer,
   [modalIsOpenSlice.name]: modalIsOpenSlice.reducer,
};

export const store = configureStore({
   reducer: staticReducers,
   middleware: getDefaultMiddleware({
      // We're currently doing things we shouldn't be with our store; let's add some exemptions for now.
      immutableCheck: {
         ignoredPaths: [
            'reviewsState',
            TEAM_MEMBERS_STATE_ROOT,
            INDIVIDUAL_DETAILS_STATE_ROOT,
            COMPLETIONS_STATE_ROOT,
            'headerState',
            'popupState',
            'bannerState',
            modalIsOpenSlice.name,
         ],
      },
      serializableCheck: {
         ignoredPaths: [
            'reviewsState',
            TEAM_MEMBERS_STATE_ROOT,
            INDIVIDUAL_DETAILS_STATE_ROOT,
            COMPLETIONS_STATE_ROOT,
            'headerState',
            'popupState',
            'bannerState',
            modalIsOpenSlice.name,
         ],
         ignoredActionPaths: ['notifications', 'popup'],
      },
   }),
});

export const setModalIsOpen = modalIsOpenSlice.setter(store);

export type RootState = ReturnType<typeof store.getState>;
